@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: local("Nunito"), url("/fonts/Nunito.woff2") format("woff2");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-display: swap;
  font-weight: 100 900;
  src: local("Nunito"), url("/fonts/Nunito-Italic.woff2") format("woff2");
}

@tailwind components;
@tailwind base;
@tailwind utilities;

@layer components {
  .display-superduper-large {
    @apply text-5xl font-extrabold leading-[3rem] tracking-[1.2px] md:text-[3.5rem] md:leading-[4.13rem];
  }
  .display-large {
    @apply text-3xl font-extrabold leading-[2.75rem] md:text-[3rem] md:leading-[4rem];
  }
  .display-small {
    @apply text-[2rem] font-extrabold leading-[2.75rem] md:text-[2.25rem];
  }
  .headline-h0 {
    @apply text-[1.3rem] leading-[1.5rem] md:text-[1.5rem] md:leading-[1.8rem];
  }
  .headline-h1 {
    @apply text-[1.75rem] font-bold leading-[2.5rem] md:text-[2rem];
  }
  .headline-h2 {
    @apply text-[1.25rem] font-bold leading-[2rem] md:text-[1.5rem];
  }
  .title-large {
    @apply text-[1.125rem] font-bold leading-[1.75rem] md:text-[1.375rem] md:leading-[1.75rem];
  }
  .title-semi-medium {
    @apply text-[1.125rem] font-bold leading-[2rem]  tracking-[0.25px];
  }
  .title-medium {
    @apply text-base font-bold tracking-[0.15px];
  }
  .title-small {
    @apply text-[0.875rem] font-bold leading-[1.25rem] tracking-[0.1px];
  }
  .body-b1 {
    @apply text-base font-normal tracking-[0.15px];
  }
  .body-b2 {
    @apply text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.25px];
  }
  .body-b3 {
    @apply text-[0.75rem] font-normal leading-[1rem] tracking-[0.4px];
  }
  .label-large {
    @apply text-[0.875rem] font-medium leading-[1.5rem] tracking-[0.1px];
  }
  .label-medium {
    @apply text-[0.75rem] font-medium leading-[1rem] tracking-[0.5px];
  }
  .label-small {
    @apply text-[0.625rem] font-medium leading-[1rem] tracking-[0.5px];
  }
  .button-large {
    @apply text-[0.875rem] font-bold leading-[1.25rem] tracking-[1.25px];
  }
  .button-small {
    @apply text-[0.75rem] font-medium leading-[1rem] tracking-[1.25px];
  }
}

@layer utilities {
  /* update highlights taps on buttons and links from blue to transparent due obscures the element */
  html {
    -webkit-tap-highlight-color: transparent;
  }

  /* prevent pull-to-refresh for Safari 16+ */
  @media screen and (pointer: coarse) {
    @supports (-webkit-backdrop-filter: blur(1px)) and
      (overscroll-behavior-y: none) {
      html {
        min-height: 100.3%;
        overscroll-behavior-y: none;
      }
    }
  }
  /* prevent pull-to-refresh for Safari 9~15 */
  @media screen and (pointer: coarse) {
    @supports (-webkit-backdrop-filter: blur(1px)) and
      (not (overscroll-behavior-y: none)) {
      html {
        height: 100%;
        overflow: hidden;
      }
      body {
        margin: 0px;
        max-height: 100%; /* or `height: calc(100% - 16px);` if body has default margin */
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }
      /* in this case to disable pinch-zoom, set `touch-action: pan-x pan-y;` on `body` instead of `html` */
    }
  }

  /* prevent pull-to-refresh for Chrome 63+ */
  body {
    overscroll-behavior-y: none;
  }

  /* make date input open when the input focus */
  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="time"]::-webkit-calendar-picker-indicator,
  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    position: absolute;
    color: transparent;
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
  }

  /* override cancel button style in input type search */
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    margin-left: 0.4em;
    background-repeat: no-repeat;
    background-image: url("/images/cancel.svg");
    cursor: pointer;
  }

  /* make hide input type number arrow for Chrome, Safari, Edge, Opera */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* make hide input type number arrow for Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /* support dynamic view port for mobile */
  .min-h-dvh {
    min-height: 100vh; /* Fallback for browsers not supporting dvh */
    min-height: 100dvh;
  }

  /* hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
